import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import Home from './components/Home';
import AddProductPage from './components/AddProductPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/add-product" element={<AddProductPage />} />

        {/* Redirigez "/" vers "/register" pour faire de l'inscription la page d'accueil */}
        <Route path="/" element={<RegisterForm />} />
      </Routes>
    </Router>
  );
}

export default App;
