import React, { useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography, List, ListItem, ListItemText } from '@mui/material';

function Home() {
    const [products, setProducts] = useState([]);
    const [message, setMessage] = useState('Bienvenue !');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://www.dlc.maximuss-dev.com/api/products/list');
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                const produitsGroupes = regrouperProduitsParNom(data);
                setProducts(produitsGroupes);
            } catch (error) {
                console.error("Erreur lors de la récupération des produits :", error);
            }
        };

        fetchProducts();
    }, []);

    const regrouperProduitsParNom = (produits) => {
        const grouped = {};

        produits.forEach((produit) => {
            if (!grouped[produit.nom]) {
                grouped[produit.nom] = [];
            }
            grouped[produit.nom].push(new Date(produit.expiryDate).toLocaleDateString());
        });

        return grouped;
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Accueil
            </Typography>
            {message && <Typography>{message}</Typography>}
            <Button variant="contained" color="primary" onClick={() => navigate('/add-product')} style={{marginBottom: '20px'}}>
                Ajouter un produit
            </Button>
            <LogoutButton />
            <Typography variant="h6">Liste des produits</Typography>
            <List>
                {Object.entries(products).map(([nom, dates]) => (
                    <ListItem key={nom}>
                        <ListItemText primary={`${nom} x ${dates.length}, DLC : ${dates.join(', ')}`} />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
}

export default Home;
