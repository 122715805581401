import React, { useState, useEffect } from 'react';
import Quagga from 'quagga';

const BarcodeScanner = ({ onDetected }) => {
    const [isScanning, setIsScanning] = useState(false); // Modifier pour empêcher le démarrage automatique
    const [borderColor, setBorderColor] = useState('red');

    useEffect(() => {
        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: document.querySelector('#barcode-scanner'),
                constraints: {
                    facingMode: "environment"
                },
            },
            decoder: {
                readers: [
                    "code_128_reader",
                    "ean_reader",
                    "ean_8_reader",
                    "i2of5_reader"
                ]
            },
            locate: true
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
            // Quagga.start(); // Commenté pour empêcher le démarrage automatique
        });

        Quagga.onDetected((result) => {
            onDetected(result);
            setIsScanning(false); // Arrêter après la détection
            Quagga.stop(); // Arrête la capture après la première détection réussie
        });

        return () => {
            Quagga.offDetected(onDetected);
            Quagga.stop();
        };
    }, [onDetected]);

    const handleStartClick = () => {
        setIsScanning(true);
        Quagga.start(); // Démarrer la capture sur clic
    };

    return (
        <div>
            
            <button onClick={handleStartClick}> <div id="barcode-scanner"></div>
            {!isScanning
            }</button>

        </div>
    );
};

export default BarcodeScanner;
