import React, { useState } from 'react';
import BarcodeScanner from './BarcodeScanner';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button } from '@mui/material';
import Quagga from 'quagga';

const ProductPage = () => {
  const [productInfo, setProductInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldStopScanning, setShouldStopScanning] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [productName, setProductName] = useState('');
  const navigate = useNavigate();

  const handleDetected = (result) => {
    const barcode = result.codeResult.code;
    console.log(`Code-barres détecté : ${barcode}`);
    setShouldStopScanning(true); // Indique de stopper le scanner
    fetchProductData(barcode);
    Quagga.stop();

    const scannerContainer = document.querySelector('#barcode-scanner');
    if (scannerContainer) {
      scannerContainer.style.display = 'none';
    }

  };

  function fetchProductData(barcode) {
    setIsLoading(true);
    fetch(`https://world.openfoodfacts.org/api/v0/product/${barcode}.json`)
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.status === 1) {
          console.log('Produit trouvé:', data.product);
          setProductInfo(data.product);
          setProductName(data.product.product_name); // Stocke le nom du produit lors de la détection
        } else {
          console.log('Produit non trouvé');
          setProductInfo(null);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Erreur lors de la récupération des données du produit:', error);
      });
}
const handleSaveProduct = () => {
  const productData = {
    nom: productName, // Utilise le nom du produit stocké
    barcode: productInfo._id,
    expiryDate: expiryDate,
  };

  const restartScanner = () => {
  setIsLoading(false); // Assurez-vous que le chargement est désactivé
  setProductInfo(null); // Réinitialise les informations du produit
  setProductName(''); // Réinitialise le nom du produit
  setExpiryDate(''); // Réinitialise la date d'expiration
  setShouldStopScanning(false); // Permet au scanner de redémarrer

  const scannerContainer = document.querySelector('#barcode-scanner');
  if (scannerContainer) {
    scannerContainer.style.display = 'block'; // Réaffiche le scanner
  }

  // Redémarre Quagga
  Quagga.start();
};


  fetch('https://www.dlc.maximuss-dev.com/api/products/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(productData),
  })
    .then(response => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Produit enregistré avec succès:', data);
      navigate('/home');
    })
    .catch((error) => {
      console.error('Erreur lors de l\'enregistrement du produit:', error);
      // Gérer l'erreur
    });
};

return (
  <Container maxWidth="sm">
    <Typography variant="h4">Scanner un produit</Typography>
    <BarcodeScanner onDetected={handleDetected} shouldStopScanning={shouldStopScanning} />
    {isLoading && <Typography>Chargement des informations du produit...</Typography>}
    {productInfo && (
      <div>
        <Typography variant="h6">Informations sur le produit</Typography>
        <Typography>Nom : {productInfo.product_name}</Typography>
        <TextField
          label="Date de péremption"
          type="date"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleSaveProduct} style={{marginTop: '20px'}}>
          Enregistrer le produit
        </Button>
      </div>
    )}
  </Container>
);
};

export default ProductPage;
