import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Supprimer le token JWT du stockage local
        localStorage.removeItem('token');

        // Rediriger vers la page de connexion
        navigate('/login');
    };

    return (
        <button onClick={handleLogout}>Déconnexion</button>
    );
};

export default LogoutButton;
